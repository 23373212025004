import { baseApi } from './baseApi'

const authApis = baseApi.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({
        //admin login api
        adminLoginApi: builder.mutation({
            query: (data) => ({
                url: 'users/admin/login',
                method: 'POST',
                body: data,
            }),
            transformResponse: async (response, meta: any) => {
                const tokenString = meta.response.headers.get('Authorization')
                const token = tokenString.replace(/^Bearer\s+/i, '').trim()
                const data = await response
                return { data, token }
            },
        }),

        //user login api
        userLoginApi: builder.mutation({
            query: (data) => ({
                url: 'users/login',
                method: 'POST',
                body: data,
            }),
            transformResponse: async (response, meta: any) => {
                const tokenString = meta.response.headers.get('Authorization')
                const token = tokenString.replace(/^Bearer\s+/i, '').trim()
                const data = await response
                return { data, token }
            },
        }),

        //user register api
        userRegister: builder.mutation({
            query: (data) => ({
                url: 'users/register',
                method: 'POST',
                body: data
            }),
            transformResponse: async (response, meta: any) => {
                const tokenString = meta.response.headers.get('Authorization')
                const token = tokenString.replace(/^Bearer\s+/i, '').trim()
                const data = await response
                return { data, token }
            },
        }),

        //foget passwrod
        forgotPassword: builder.mutation({
            query: (body) => ({
                url: `users/forget_password`,
                method: 'POST',
                body
            })
        }),

        //verify code
        verifyCode: builder.mutation({
            query: (data) => ({
                url: 'users/forget_password/verify',
                method: 'POST',
                body: data
            })
        }),

        //reset password
        resetPassword: builder.mutation({
            query: (data) => ({
                url: 'users/reset_password',
                method: "POST",
                body: data
            })
        }),

        //social login
        sociaLogin: builder.mutation({
            query: (data) => ({
                url: 'users/social_media_login',
                method: 'POST',
                body: data
            }),
            transformResponse: async (response, meta: any) => {
                const tokenString = meta.response.headers.get('Authorization')
                const token = tokenString.replace(/^Bearer\s+/i, '').trim()
                const data = await response
                return { data, token }
            },
        })


    }),
})

export const { useUserLoginApiMutation, useAdminLoginApiMutation, useUserRegisterMutation, useVerifyCodeMutation, useResetPasswordMutation, useSociaLoginMutation, useForgotPasswordMutation } = authApis
