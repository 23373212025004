import styled from "styled-components";

export const FormWrapper = styled.div`

.ant-form-item-required {
    color: white !important;
}

.submit-btn {
    margin-top: 20px;
    background: #FEBE10;
}

.submit-btn:hover {
    background: #FEBE10 !important;

}

svg {
    color: #006641 !important;
}

.white-color {
    color: #fff !important;
}

.google-icon {
    font-size: 16px;
}

.google-btn:hover {
    span {
        color: black;
    }    
}


`