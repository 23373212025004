import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom'
import { AUTHENTICATED_ENTRY } from '../configs/AppConfig'
import { protectedRoutes, publicRoutes } from '../configs/RoutesConfig'
import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'
import AppRoute from './AppRoute'
import { useSelector } from 'react-redux'
import { IRole, IUser } from '../models'

const Routes = () => {
    interface RouteConfig {
        key: string | null
        path: string | null
        component: any
        meta: any
        rolePermissions?: string[]
    }

    const userDetails: IUser = useSelector((state: any) => state?.auth?.user_data)
    return (
        <RouterRoutes>
            <Route path="/" element={<ProtectedRoute />}>
                <Route path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />
                {protectedRoutes.map((route: RouteConfig) => {
                    const hasPermission = route?.rolePermissions?.includes('ALL') || userDetails?.roles?.some((role: IRole) => route?.rolePermissions?.includes(role?.role?.name))
                    return hasPermission ? (
                        <Route key={route.key} path={route.path} element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />} />
                    ) : (
                        <Route key={route.key} path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />
                    )
                })}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
            <Route path="/" element={<PublicRoute />}>
                {publicRoutes.map((route: RouteConfig) => {
                    return <Route key={route.path} path={route.path} element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />} />
                })}
            </Route>
        </RouterRoutes>
    )
}

export default Routes
