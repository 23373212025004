import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Button, Form, Input, Divider } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { signInWithPopup } from 'firebase/auth'
import { GoogleSVG } from '../../../assets/svg/icon'
import CustomIcon from '../../../components/util-components/CustomIcon'
import { setAuthData } from '../../../store/slices/authSlice'
import { useSociaLoginMutation, useUserLoginApiMutation } from '../../../store/api-slices/authApis'
import { firebaseAuth, googleProvider } from '../../../configs/FirebaseConfig'
import NotificationMessage from '../../../components/shared-components/NotificationMessage'
import { FormWrapper } from './styles'

interface IProps {
    otherSignIn?: boolean
}

export const LoginForm: FC<IProps> = ({ otherSignIn }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const redirect = searchParams.get('redirect')

    const initialCredential = {
        email: '',
        password: '',
    }

    const [userSignIn, { isLoading }] = useUserLoginApiMutation()

    const [socialLogin, { isLoading: socailLoading }] = useSociaLoginMutation()

    const onLogin = async (values: any) => {
        try {
            await userSignIn(values)
                .unwrap()
                .then((res: any) => {
                    let payload = {
                        token: res?.token ?? null,
                        user_data: res?.data?.data ?? null,
                    }
                    dispatch(setAuthData(payload))
                    if (redirect) navigate(redirect)
                })
                .catch((error: any) => {
                    NotificationMessage.error({ content: error?.data?.message ?? error?.error, duration: 2 })
                    console.log(error, 'error')
                })
        } catch (error) {
            console.log(error, 'error')
        }
    }

    const onGoogleLogin = async () => {
        signInWithPopup(firebaseAuth, googleProvider)
            .then(async (res: any) => {
                const { uid: userId, email, displayName, photoURL } = res?.user
                const payload = { userId, email, username: displayName, profilePictureUrl: photoURL }
                await socialLogin(payload)
                    .unwrap()
                    .then((res: any) => {
                        let payload = {
                            token: res?.token ?? null,
                            user_data: res?.data?.data ?? null,
                        }
                        dispatch(setAuthData(payload))
                        if (redirect) navigate(redirect)
                    })
                    .catch((error) => {
                        NotificationMessage.error({ content: error?.data?.message, duration: 2 })
                    })
            })
            .catch((error) => {
                NotificationMessage.error({ content: error?.message, duration: 2 })
            })
    }

    const renderOtherSignIn = (
        <div>
            <Divider style={{ border: 'white' }}>
                <span className="text-muted font-size-base font-weight-normal white-color">or connect with</span>
            </Divider>
            <div className="d-flex justify-content-center mb-4 google-btn">
                <Button onClick={() => onGoogleLogin()} className="mr-2" disabled={socailLoading} icon={<CustomIcon className="google-icon" svg={GoogleSVG} />}>
                    Google
                </Button>
                {/* <Button onClick={() => onFacebookLogin()} icon={<CustomIcon svg={FacebookSVG} />} disabled={loading}>
                    Facebook
                </Button> */}
            </div>
        </div>
    )

    return (
        <>
            <FormWrapper>
                <Form className="mt-4" layout="vertical" name="login-form" initialValues={initialCredential} onFinish={onLogin}>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your email',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email!',
                            },
                        ]}
                    >
                        <Input placeholder="Enter email" prefix={<MailOutlined className="text-primary" autoComplete="new-email" />} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label={'Password'}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your password',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Enter password" prefix={<LockOutlined className="text-primary" />} autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item>
                        <Button className="submit-btn" type="primary" htmlType="submit" block loading={isLoading}>
                            Sign In
                        </Button>
                    </Form.Item>
                    {otherSignIn ? renderOtherSignIn : null}
                </Form>
            </FormWrapper>
        </>
    )
}

LoginForm.defaultProps = {
    otherSignIn: true,
}

const mapStateToProps = ({ auth }) => {
    const { loading, message, showMessage, token, redirect } = auth
    return { loading, message, showMessage, token, redirect }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
