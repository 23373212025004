class Utils {
    static ENCRIPTION_KEY = process.env.NODE_ENV
    static BACK_END_API_URL = 'https://dev.api.muslistings.com/api/v1'
    // static BACK_END_API_URL = 'http://43.204.193.251:8080/api/v1'
    static GOOGLE_MAP_API_KEY = 'AIzaSyCuN6CyyXZgI0c_Cyy6UPINNMiWn7-5_DA'
    static YELLOW_COLOR = '#FEBE10'
    static GREEN_COLOR = '#006641'
    static MUS_GREEN = '#00A96C'
    static MUS_HONEY = '#BC8C09'
    static OTHER_GREEN = '#008037'

    static MARKER_ICON = {
        url: 'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><circle cx="16" cy="16" r="12" fill="%23007bff" fill-opacity="0.15"/><circle cx="16" cy="16" r="8" fill="%23ffffff"/><circle cx="16" cy="16" r="6" fill="%23007bff"/></svg>',
    }

    static RED_MARKER_ICON = {
        url: 'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path d="M16 0C9.372 0 4 5.372 4 12c0 10.667 12 20 12 20s12-9.333 12-20C28 5.372 22.628 0 16 0zm0 18c-3.333 0-6-2.667-6-6s2.667-6 6-6 6 2.667 6 6-2.667 6-6 6z" fill="%23ff0000"/></svg>',
    }

    static NEW_RED_MARKER_ICON = {
        url: 'data:image/svg+xml;charset=UTF-8,<svg width="32" height="32" viewBox="0 0 82 108" xmlns="http://www.w3.org/2000/svg"><path d="M41 0C18.6339 0 0.5 18.1339 0.5 40.5C0.5 49.3071 3.38731 57.3885 8.18656 64.0069C8.27263 64.1655 8.28612 64.3427 8.384 64.4946L35.384 104.995C36.6361 106.873 38.7455 108 41 108C43.2545 108 45.3639 106.873 46.616 104.995L73.616 64.4946C73.7156 64.3427 73.7274 64.1655 73.8134 64.0069C78.6127 57.3885 81.5 49.3071 81.5 40.5C81.5 18.1339 63.3661 0 41 0ZM41 54C33.5446 54 27.5 47.9554 27.5 40.5C27.5 33.0446 33.5446 27 41 27C48.4554 27 54.5 33.0446 54.5 40.5C54.5 47.9554 48.4554 54 41 54Z" fill="%23E82F2F"/></svg>',
    }

    static RECENTER_ICON = `
<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#666666">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
        <title>location-current-solid</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="invisible_box" data-name="invisible box">
                <rect width="48" height="48" fill="none"></rect>
            </g>
            <g id="icons_Q2" data-name="icons Q2">
                <g>
                    <path d="M44,22H39.9A16.1,16.1,0,0,0,26,8.1h0V4a2,2,0,0,0-4,0V8h0A16.1,16.1,0,0,0,8.1,22H4a2,2,0,0,0,0,4H8.1A16.1,16.1,0,0,0,22,39.9h0v4a2,2,0,0,0,4,0V40h0A16.1,16.1,0,0,0,39.9,26H44a2,2,0,0,0,0-4ZM24,36A12,12,0,1,1,36,24,12,12,0,0,1,24,36Z" fill="#666666"></path>
                    <circle cx="24" cy="24" r="7" fill="#666666"></circle>
                </g>
            </g>
        </g>
    </g>
</svg>
`

    static CATEGORY_MARKER_ICON = {
        url: 'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="24" height="24"><path d="M16 0C9.372 0 4 5.372 4 12c0 10.667 12 20 12 20s12-9.333 12-20C28 5.372 22.628 0 16 0zm0 18c-3.333 0-6-2.667-6-6s2.667-6 6-6 6 2.667 6 6-2.667 6-6 6z" fill="%23FFA500"/></svg>',
    }

    /**
     * Get first character from first & last sentences of a username
     * @param {String} name - Username
     * @return {String} 2 characters string
     */
    static getNameInitial(name) {
        let initials = name.match(/\b\w/g) || []
        return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    }

    /**
     * @param {String} url - url
     * @return {String}
     */

    static removeLastSegment(url: string) {
        // Find the index of the last '/'
        const lastSlashIndex = url.lastIndexOf('/')

        // If '/' is found, extract the substring up to that index
        if (lastSlashIndex !== -1) {
            return url.substring(0, lastSlashIndex)
        }

        // If no '/' is found, return the original string
        return url
    }

    //method to check for child routes
    static checkChildRoutes(childArray: any[], path: string) {
        let check = childArray?.some((item: any) => String(item)?.includes(this.removeLastSegment(path)))
        return check
    }

    /**
     * Get current path related object from Navigation Tree
     * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
     * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
     * @return {Object} object that contained the path string
     */
    static getRouteInfo(navTree: any, path: string) {
        if (navTree?.path === path || this.checkChildRoutes(navTree?.childRoutes, path)) {
            return navTree
        }
        let route
        for (let p in navTree) {
            if (navTree?.hasOwnProperty(p) && typeof navTree[p] === 'object') {
                route = this.getRouteInfo(navTree[p], path)
                if (route) {
                    return route
                }
            }
        }
        return route
    }

    /**
     * Get accessible color contrast
     * @param {String} hex - Hex color code e.g '#3e82f7'
     * @return {String} 'dark' or 'light'
     */
    static getColorContrast(hex) {
        if (!hex) {
            return 'dark'
        }
        const threshold = 130
        const hRed = hexToR(hex)
        const hGreen = hexToG(hex)
        const hBlue = hexToB(hex)
        function hexToR(h) {
            return parseInt(cutHex(h).substring(0, 2), 16)
        }
        function hexToG(h) {
            return parseInt(cutHex(h).substring(2, 4), 16)
        }
        function hexToB(h) {
            return parseInt(cutHex(h).substring(4, 6), 16)
        }
        function cutHex(h) {
            return h.charAt(0) === '#' ? h.substring(1, 7) : h
        }
        const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000
        if (cBrightness > threshold) {
            return 'dark'
        } else {
            return 'light'
        }
    }

    /**
     * Darken or lighten a hex color
     * @param {String} color - Hex color code e.g '#3e82f7'
     * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
     * @return {String} Darken or lighten color
     */
    static shadeColor(color, percent) {
        let R = parseInt(color.substring(1, 3), 16)
        let G = parseInt(color.substring(3, 5), 16)
        let B = parseInt(color.substring(5, 7), 16)
        // R = parseInt(R * (100 + percent) / 100);
        // G = parseInt(G * (100 + percent) / 100);
        // B = parseInt(B * (100 + percent) / 100);
        R = R < 255 ? R : 255
        G = G < 255 ? G : 255
        B = B < 255 ? B : 255
        const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16)
        const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16)
        const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16)
        return `#${RR}${GG}${BB}`
    }

    /**
     * Convert RGBA to HEX
     * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
     * @return {String} HEX color
     */
    static rgbaToHex(rgba) {
        const trim = (str) => str.replace(/^\s+|\s+$/gm, '')
        const inParts = rgba.substring(rgba.indexOf('(')).split(','),
            r = parseInt(trim(inParts[0].substring(1)), 10),
            g = parseInt(trim(inParts[1]), 10),
            b = parseInt(trim(inParts[2]), 10),
            a: any = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2)
        const outParts = [r.toString(16), g.toString(16), b.toString(16), Math.round(parseInt((a * 255).toString(16).substring(0, 2)))]

        outParts.forEach(function (part: string, i) {
            if (part.length === 1) {
                outParts[i] = '0' + part
            }
        })
        return `#${outParts.join('')}`
    }

    /**
     * Returns either a positive or negative
     * @param {Number} number - number value
     * @param {any} positive - value that return when positive
     * @param {any} negative - value that return when negative
     * @return {any} positive or negative value based on param
     */
    static getSignNum(number, positive, negative) {
        if (number > 0) {
            return positive
        }
        if (number < 0) {
            return negative
        }
        return null
    }

    /**
     * Returns either ascending or descending value
     * @param {Object} a - antd Table sorter param a
     * @param {Object} b - antd Table sorter param b
     * @param {String} key - object key for compare
     * @return {any} a value minus b value
     */
    static antdTableSorter(a, b, key) {
        if (typeof a[key] === 'number' && typeof b[key] === 'number') {
            return a[key] - b[key]
        }

        if (typeof a[key] === 'string' && typeof b[key] === 'string') {
            a = a[key].toLowerCase()
            b = b[key].toLowerCase()
            return a > b ? -1 : b > a ? 1 : 0
        }
        return
    }

    /**
     * Filter array of object
     * @param {Array} list - array of objects that need to filter
     * @param {String} key - object key target
     * @param {any} value  - value that excluded from filter
     * @return {Array} a value minus b value
     */
    static filterArray(list, key, value) {
        let data = list
        if (list) {
            data = list.filter((item) => item[key] === value)
        }
        return data
    }

    /**
     * Remove object from array by value
     * @param {Array} list - array of objects
     * @param {String} key - object key target
     * @param {any} value  - target value
     * @return {Array} Array that removed target object
     */
    static deleteArrayRow(list, key, value) {
        let data = list
        if (list) {
            data = list.filter((item) => item[key] !== value)
        }
        return data
    }

    /**
     * Wild card search on all property of the object
     * @param {Number | String} input - any value to search
     * @param {Array} list - array for search
     * @return {Array} array of object contained keyword
     */
    static wildCardSearch(list, input) {
        const searchText = (item) => {
            for (let key in item) {
                if (item[key] == null) {
                    continue
                }
                if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
                    return true
                }
            }
        }
        list = list.filter((value) => searchText(value))
        return list
    }

    /**
     * Get Breakpoint
     * @param {Object} screens - Grid.useBreakpoint() from antd
     * @return {Array} array of breakpoint size
     */
    static getBreakPoint(screens) {
        let breakpoints = []
        for (const key in screens) {
            if (screens.hasOwnProperty(key)) {
                const element = screens[key]
                if (element) {
                    breakpoints.push(key)
                }
            }
        }
        return breakpoints
    }
}

export default Utils
