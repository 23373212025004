import { Link, useNavigate } from 'react-router-dom'
import { Card, Row, Col, Form, Input, Button } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import Utils from '../../../../utils'
import { FormWrapper } from '../../components/styles'
import { useResetPasswordMutation } from '../../../../store/api-slices/authApis'
import NotificationMessage from '../../../../components/shared-components/NotificationMessage'

const cardStyles = { background: '#006641', boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.4)', border: '1px solid #006641' }

const ResetPassword = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const [resetPassword, { isLoading }] = useResetPasswordMutation()

    async function handleResetPassword(values) {
        try {
            const { password } = values
            const email = localStorage.getItem('user_email')
            const code = localStorage.getItem('otp')
            const payload = { password, email, code }
            await resetPassword(payload)
                .unwrap()
                .then(() => {
                    NotificationMessage.success({ content: 'Password set successfully', duration: 2 })
                    localStorage.removeItem('user_email')
                    localStorage.removeItem('otp')
                    navigate('/auth/login')
                })
                .catch((error) => {
                    NotificationMessage.error({ content: error?.data?.message, duration: 2 })
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
                <Col xs={20} sm={20} md={20} lg={8}>
                    <Card style={cardStyles}>
                        <div className="my-2">
                            <div className="text-center">
                                <div className="text-center">
                                    <img className="img-fluid" width={200} height={200} src={'/images/mustlisting_logo.png'} alt="" />
                                </div>
                            </div>
                            <Row justify="center">
                                <Col xs={24} sm={24} md={20} lg={20}>
                                    <FormWrapper>
                                        <Form className="mt-4" form={form} layout="vertical" name="register-form" onFinish={handleResetPassword}>
                                            <Form.Item
                                                name="password"
                                                label="Password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your password!',
                                                    },
                                                    {
                                                        message: 'Password must be a minimum of 8 characters including number, Upper, Lower and one Special character.',
                                                        pattern: new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!-_*]).{8,}$'),
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password placeholder="Enter password" prefix={<LockOutlined className="text-primary" />} autoComplete="new-password" />
                                            </Form.Item>
                                            <Form.Item
                                                name="confirm"
                                                label="Confirm Password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please confirm your password!',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve()
                                                            }
                                                            return Promise.reject('Password not matched!')
                                                        },
                                                    }),
                                                ]}
                                                hasFeedback
                                            >
                                                <Input.Password placeholder="Enter password" prefix={<LockOutlined className="text-primary" />} autoComplete="new-password" />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button className="submit-btn" type="primary" htmlType="submit" block loading={isLoading}>
                                                    Send
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </FormWrapper>
                                    <div className="mt-3" style={{ justifyContent: 'center', display: 'grid', textAlign: 'center' }}>
                                        <span style={{ color: 'white' }}>
                                            Back to{' '}
                                            <Link style={{ color: Utils.YELLOW_COLOR }} to="/auth/login">
                                                Login
                                            </Link>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ResetPassword
