import { Card, Row, Col, Form, Input, Button } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import Utils from '../../../../utils'
import { FormWrapper } from '../../components/styles'
import { useForgotPasswordMutation } from '../../../../store/api-slices/authApis'
import NotificationMessage from '../../../../components/shared-components/NotificationMessage'

const cardStyles = { background: '#006641', boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.4)', border: '1px solid #006641' }

const ForgotPassword = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const [forgotPassword, { isLoading }] = useForgotPasswordMutation()

    const onSend = async (values: any) => {
        try {
            const { email } = values
            await forgotPassword({ email })
                .unwrap()
                .then((res) => {
                    localStorage.setItem('user_email', email)
                    navigate('/auth/verify-code')
                })
                .catch((error) => {
                    NotificationMessage.error({ content: error?.data?.message, duration: 2 })
                })
        } catch (error) {
            console.log(error, 'error')
        }
    }

    return (
        <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
                <Col xs={20} sm={20} md={20} lg={8}>
                    <Card style={cardStyles}>
                        <div className="my-2">
                            <div className="text-center">
                                <div className="text-center">
                                    <img className="img-fluid" width={200} height={200} src={'/images/mustlisting_logo.png'} alt="" />
                                </div>
                            </div>
                            <Row className="mt-4" justify="center">
                                <Col xs={24} sm={24} md={20} lg={20}>
                                    <FormWrapper>
                                        <Form form={form} layout="vertical" name="forget-password" onFinish={onSend}>
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your email',
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: 'Please enter a valid email!',
                                                    },
                                                ]}
                                                label="Enter your Email"
                                            >
                                                <Input placeholder="Email Address" prefix={<MailOutlined className="text-primary" />} />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button className="submit-btn" loading={isLoading} type="primary" htmlType="submit" block>
                                                    {'Send'}
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                        <div className="mt-3" style={{ justifyContent: 'center', display: 'grid', textAlign: 'center' }}>
                                            <span style={{ color: 'white' }}>
                                                Back to{' '}
                                                <Link style={{ color: Utils.YELLOW_COLOR }} to="/auth/login">
                                                    Login
                                                </Link>
                                            </span>
                                        </div>
                                    </FormWrapper>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ForgotPassword
