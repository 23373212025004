import moment from 'moment'
import CryptoJS from 'crypto-js'

import Utils from '.'
import { IBusiness, IOpeningHours, IRole } from '../models'

export const encriptData = (data: any) => {
    let encription = CryptoJS.AES.encrypt(JSON.stringify(data), Utils.ENCRIPTION_KEY)
    return encription
}

export const decriptData = (data: any) => {
    let bytes = CryptoJS.AES.decrypt(data, Utils.ENCRIPTION_KEY)
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

    return decryptedData
}

export const currentDay = moment().day() - 1 < 0 ? 6 : moment().day() - 1

export function isBusinessOpen(business: IBusiness) {
    const currentTime: any = moment().format('HHmm')

    const businessOpeningHour = business?.openingHours[currentDay] ?? null
    if (!businessOpeningHour) return undefined

    const closingTime: any = formatTime(businessOpeningHour?.closingTime)
    const openingTime: any = formatTime(businessOpeningHour?.openingTime)

    if (!closingTime || !openingTime) return undefined

    const closingMoment = moment()?.set('hour', closingTime?.substr(0, 2))?.set('minute', closingTime?.substr(2))
    const openingMoment = moment()?.set('hour', openingTime?.substr(0, 2))?.set('minute', openingTime?.substr(2))

    if (closingMoment?.isBefore(openingMoment)) {
        closingMoment?.add(1, 'day')
    }

    const currentMoment = moment()?.set('hour', currentTime?.substr(0, 2))?.set('minute', currentTime?.substr(2))

    const isOpen = currentMoment?.isBetween(openingMoment, closingMoment, null, '[]')

    if (isOpen) {
        return businessOpeningHour
    }
    return null
}

export function showLimitedCharacters(text: string, limit: number) {
    if (text?.length <= limit) {
        return text
    } else {
        return text?.slice(0, limit) + '...'
    }
}

export function customRound(number: number) {
    if (number % 1 < 0.25) {
        return Math.floor(number)
    } else if (number % 1 >= 0.75) {
        return Math.ceil(number)
    } else {
        return Math.floor(number) + 0.5
    }
}

export function formatDate(date: string, currentFormat: string = 'HHmm', returnFormat: string = 'h:mm a.') {
    const formattedTime = date?.padStart(4, '0')
    const momentObj = moment(formattedTime, currentFormat)
    if (momentObj.isValid()) {
        // Format the time, and remove the ":00" if present
        const formattedTime = momentObj.format(returnFormat)
        return formattedTime.replace(':00', '')
    }
    return '-'
}

function formatTime(time: string | undefined): string | undefined {
    if (!time) return undefined

    // If time is a single digit, prepend four zeros; otherwise, pad to 4 characters
    const formattedTime = time?.length === 1 ? `000${time}` : time?.padStart(4, '0')
    return formattedTime
}

export const convertToArray = (typesString: string) => {
    if (!typesString) return []

    return typesString
        .replace(/[\[\]\s]/g, '') // Remove brackets and whitespace
        .split(',')
        ?.map((item) => {
            return item
                .toLowerCase()
                .replace(/_/g, ' ')
                .replace(/\b\w/g, (char) => char.toUpperCase())
        })
}

export const checkUserRoles = (userRoles: IRole[], checkRole: string) => {
    const check = userRoles?.some((iRole: IRole) => iRole?.role.name === checkRole)
    return check ?? false
}

export function capitalizeFirstLetter(value: string | undefined) {
    if (!value) return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export function getFirstLetter(name: string) {
    const firstLetter = name?.charAt(0).toUpperCase()
    return firstLetter
}

export const roundOffDecimal = (value: number, digit: number = 4): number => {
    return Number(value.toFixed(digit))
}

export function classifyFileByType(file) {
    // Define file extensions for images and videos
    const imageExtensions = ['png', 'jpeg', 'jpg', 'gif']
    const videoExtensions = ['mp4', 'avi', 'mov', 'mkv']

    // Extract the file extension
    const extension = file?.url?.split('.').pop().toLowerCase()

    // Check file type
    if (imageExtensions.includes(extension)) {
        return { type: 1, file }
    } else if (videoExtensions.includes(extension)) {
        return { type: 2, file }
    } else {
        return { type: 1, file }
    }
}

export const calculateCenter = (coordinates: { lat: number; lng: number }[]) => {
    const totalCoordinates = coordinates.length
    if (totalCoordinates === 0) return { lat: 0, lng: 0 } // Return a default value if no coordinates

    const sum = coordinates.reduce(
        (acc, { lat, lng }) => {
            acc.lat += lat
            acc.lng += lng
            return acc
        },
        { lat: 0, lng: 0 }
    )

    const result = {
        lat: sum.lat / totalCoordinates,
        lng: sum.lng / totalCoordinates,
    }

    return result
}

export function checkOpeningTime(businessItem: IOpeningHours, index?: number) {
    const time = index ? businessItem?.closingTime : businessItem?.openingTime

    if (time?.length > 1) {
        // Ensure time is 4 characters long (HHmm)
        const formattedTime = time?.padStart(4, '0')
        return moment(formattedTime, 'HHmm').isValid() ? moment(formattedTime, 'HHmm').format('hh:mm') : undefined
    }
    return undefined
}

export const isValidEmail = (email: string | null) => {
    if (!email) return false
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
}
