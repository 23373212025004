import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from '../constants/ThemeConstant'
import { env } from './EnvironmentConfig'

export const APP_NAME = 'MUSLISTING'
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app'
export const AUTH_PREFIX_PATH = '/auth'
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/home`
export const UNAUTHENTICATED_ENTRY = '/login'
// export const UNAUTHENTICATED_ENTRY = '/portal'

export const THEME_CONFIG = {
    navCollapsed: true,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',

    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR,
    blankLayout: false,
}

export const GLOBAL_CONSTANTS = {
    CATEGORY_RESTAURANT: '18EsrAoX6Tl0rndhSHxnzTBFVW2i9F',
}

export const USER_ROUTE = {
    MANAGE: '/user/manage',
    UPDATE: '/user/update',
    CREATE: '/user/create',
    ACCOUNT_SETTING: '/user/account-setting',
    PROFILE: '/user/profile',
    APPLY_FOODIE: '/user/apply-foodie',
    APPLY_FOODIE_SUCCESS: '/user/apply-foodie/success',
    BUSINESS_LISTINGS: '/user/business-listings',
    USER_REVIEWS: '/user/user-reviews',
}

export const BUSINESS_ROUTE = {
    MANAGE: '/business/manage',
    CREATE: '/business/create',
    UPDATE: '/business/update',
    VIEW: '/business/view/:id',
}

export const BUSINESS_DATA_ROUTE = {
    SEARCH: '/data/search',
    ADD: '/data/add',
}

export const SETTINGS_ROUTE = {
    CATEGORIES_MANAGE: '/settings/category/manage',
    CUISINE_MANAGE: '/settings/cuisine/manage',
}

export const FAVOURITE_PATH = {
    MANAGE: `/favourite/manage`,
    VIEW: `/favourite/view`,
    CREATE: `/favourite/create`,
    UPDATE: `/favourite/update`,
}

export const BLOGGERS_PATH = {
    MANAGE: `/blogger/manage`,
    VIEW: `/blogger/view`,
    CONTENT_MANAGE: `/blogger/content/manage`,
    CONTENT_VIEW: `/blogger/content/view`,
    CONTENT_CREATE: `/blogger/content/create`,
    CONTENT_UPDATE: `/blogger/content/update`,
}

export const BUSINESS_CATEGORY = {
    MANAGE: '/category/manage',
    VIEW: `/category/view`,
}

export const USER_ROLE_NAME = {
    FOODIE: 'FOODIE',
    BUSINESS_OWNER: 'BUSINESS_OWNER',
}

export const MORE_MENU = {
    MANAGE: '/more/manage',
}
