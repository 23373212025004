import { GoogleAuthProvider, getAuth } from 'firebase/auth'
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
    apiKey: 'AIzaSyCjaACZMQHlSfevzRfpgSmw8P5-gITDLOE',
    authDomain: 'muslistings-inc.firebaseapp.com',
    projectId: 'muslistings-inc',
    storageBucket: 'muslistings-inc.appspot.com',
    messagingSenderId: '939021499351',
    appId: '1:939021499351:web:fc1eacdb55270ad9592992',
}

const app = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(app)
export const googleProvider = new GoogleAuthProvider()
