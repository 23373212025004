import { useState } from 'react'
import { Card, Row, Col, Button, Spin } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import Utils from '../../../../utils'
import { FormWrapper } from '../../components/styles'
import NotificationMessage from '../../../../components/shared-components/NotificationMessage'
import { useForgotPasswordMutation, useVerifyCodeMutation } from '../../../../store/api-slices/authApis'

const cardStyles = { background: '#006641', boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.4)', border: '1px solid #006641' }

const VerifyCode = () => {
    const userEmail = localStorage.getItem('user_email')
    const navigate = useNavigate()
    const [otp, setOtp] = useState<string>('')

    const [verifyCode, { isLoading }] = useVerifyCodeMutation()

    const [forgotPassword, { isLoading: resendLoading }] = useForgotPasswordMutation()

    async function handleResendCode() {
        if (userEmail) {
            try {
                await forgotPassword({ email: userEmail })
                    .unwrap()
                    .then((res) => {
                        NotificationMessage.success({ content: res?.message, duration: 2 })
                    })
                    .catch((error) => {
                        NotificationMessage.error({ content: error?.data?.message, duration: 2 })
                    })
            } catch (error) {
                console.log(error, 'error')
            }
        }
    }

    const handleSendOtp = async () => {
        try {
            if (userEmail) {
                const payload = { email: userEmail, code: otp }
                await verifyCode(payload)
                    .unwrap()
                    .then(() => {
                        localStorage.setItem('otp', otp)
                        navigate('/auth/reset-password')
                    })
                    .catch((error) => {
                        NotificationMessage.error({ content: error?.data?.message, duration: 2 })
                    })
            }
        } catch (error) {
            console.log(error, 'error')
        }
    }

    return (
        <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
                <Col xs={20} sm={20} md={20} lg={8}>
                    <Card style={cardStyles}>
                        <div className="my-2">
                            <div className="text-center">
                                <div className="text-center">
                                    <img className="img-fluid" width={200} height={200} src={'/images/mustlisting_logo.png'} alt="" />
                                </div>
                            </div>
                            <Spin spinning={resendLoading}>
                                <Row className="mt-3" justify="center">
                                    <Col xs={24} sm={24} md={20} lg={20}>
                                        <FormWrapper>
                                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                                <p style={{ color: 'white', fontSize: '16px' }}>Enter the OTP code send on you email</p>
                                            </div>
                                            <div className="mt-2" style={{ justifyContent: 'center', display: 'flex' }}>
                                                <OtpInput
                                                    inputStyle={{ fontSize: '32px', borderRadius: '8px', border: `1px solid ${Utils.GREEN_COLOR}`, padding: '8px 10px' }}
                                                    // inputType="number"
                                                    value={otp}
                                                    onChange={setOtp}
                                                    numInputs={6}
                                                    renderSeparator={<span style={{ padding: '8px' }}></span>}
                                                    renderInput={(props) => <input {...props} />}
                                                    inputType="tel"
                                                />
                                            </div>

                                            <Button
                                                onClick={handleSendOtp}
                                                disabled={otp?.length < 6}
                                                className="submit-btn mt-5"
                                                loading={isLoading}
                                                type="primary"
                                                htmlType="submit"
                                                block
                                            >
                                                {'Send'}
                                            </Button>

                                            <div className="mt-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span style={{ color: 'white' }}>
                                                    Back to{' '}
                                                    <Link style={{ color: Utils.YELLOW_COLOR }} to="/auth/login">
                                                        Login
                                                    </Link>
                                                </span>
                                                <span onClick={handleResendCode} style={{ cursor: 'pointer', color: Utils.YELLOW_COLOR }}>
                                                    Resend Code
                                                </span>
                                            </div>
                                        </FormWrapper>
                                    </Col>
                                </Row>
                            </Spin>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default VerifyCode
