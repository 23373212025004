import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { AUTHENTICATED_ENTRY } from '../configs/AppConfig'

interface State {
    auth: any
}

const backgroundStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(/images/background-image.png)`,
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
}

const PublicRoute = () => {
    const { token } = useSelector((state: State) => state.auth)

    return token ? (
        <Navigate to={AUTHENTICATED_ENTRY} />
    ) : (
        <div style={backgroundStyle}>
            <Outlet />
        </div>
    )
}

export default PublicRoute
