import { createSlice } from '@reduxjs/toolkit'
import { AUTH_TOKEN, USER_DATA } from '../../constants/AuthConstant'
import { decriptData, encriptData } from '../../utils/helperFunctions'

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN) ? decriptData(localStorage.getItem(AUTH_TOKEN)) : null,
    user_data: localStorage.getItem(USER_DATA) ? decriptData(localStorage.getItem(USER_DATA)) : null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthData: (state, { payload }) => {
            const { token, user_data }: any = payload
            localStorage.setItem(AUTH_TOKEN, String(encriptData(token)))
            localStorage.setItem(USER_DATA, String(encriptData(user_data)))
            state.token = token
            state.user_data = user_data
        },
        clearAuthData: (state) => {
            localStorage.removeItem(AUTH_TOKEN)
            localStorage.removeItem(USER_DATA)
            state.token = null
            state.user_data = null
        },
        setUserData: (state, { payload }) => {
            const { user_data }: any = payload
            localStorage.setItem(USER_DATA, String(encriptData(user_data)))
            state.user_data = user_data
        },
    },
})

export const { setAuthData, clearAuthData, setUserData } = authSlice.actions

export default authSlice.reducer
