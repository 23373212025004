import { Button, Form, Input } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import ReCAPTCHA from 'react-google-recaptcha'
import { connect, useDispatch } from 'react-redux'
import { setAuthData } from '../../../store/slices/authSlice'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { useUserRegisterMutation } from '../../../store/api-slices/authApis'
import NotificationMessage from '../../../components/shared-components/NotificationMessage'
import { FormWrapper } from './styles'

export const RegisterForm = (props) => {
    const { token, redirect, showMessage, hideAuthMessage, allowRedirect = true } = props
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [userSignUp, { isLoading: singupLoading }] = useUserRegisterMutation()

    const onSignUp = async (values: any) => {
        try {
            const { email, password } = values
            const payload = { email, password }
            await userSignUp(payload)
                .unwrap()
                .then((res: any) => {
                    let payload = {
                        token: res?.token ?? null,
                        user_data: res?.data?.data ?? null,
                    }
                    dispatch(setAuthData(payload))
                    navigate('/app/user/account-setting')
                })
                .catch((error) => {
                    NotificationMessage.error({ content: error?.data?.message, duration: 2 })
                })
        } catch (error) {
            console.log(error, 'error')
        }
    }

    useEffect(() => {
        if (token !== null && allowRedirect) {
            navigate(redirect)
        }
        if (showMessage) {
            const timer = setTimeout(() => hideAuthMessage(), 3000)
            return () => {
                clearTimeout(timer)
            }
        }
    })

    return (
        <>
            <FormWrapper>
                <Form className="mt-4" form={form} layout="vertical" name="register-form" onFinish={onSignUp}>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your email',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input placeholder="Enter email" prefix={<MailOutlined className="text-primary" autoComplete="new-email" />} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your password!',
                            },
                            {
                                message: 'Password must be a minimum of 8 characters including number, Upper, Lower and one Special character.',
                                pattern: new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!-_*]).{8,}$'),
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="Enter password" prefix={<LockOutlined className="text-primary" />} autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject('Password not matched!')
                                },
                            }),
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="Enter password" prefix={<LockOutlined className="text-primary" />} autoComplete="new-password" />
                    </Form.Item>
                    <div className="flex justifyCenter">
                        {/* <ReCAPTCHA sitekey="6Lcf9FwpAAAAABgBgsVJsjFQ_3H_uIaf_CryPBrA" onChange={(value: string) => setCaptchaValue(value)} /> */}
                    </div>
                    <Form.Item>
                        <Button className="submit-btn" type="primary" htmlType="submit" block loading={singupLoading}>
                            Sign Up
                        </Button>
                    </Form.Item>
                </Form>
            </FormWrapper>
        </>
    )
}

const mapStateToProps = ({ auth }) => {
    const { loading, message, showMessage, token, redirect } = auth
    return { loading, message, showMessage, token, redirect }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
