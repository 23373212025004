import { createSlice } from '@reduxjs/toolkit'

export interface ICoordinate {
    lat: number
    lng: number
}

const defaultCoordinate: ICoordinate = {
    lat: 24.8607,
    lng: 67.0011,
}

const initialState = {
    mapCoordinates: defaultCoordinate,
    zoom: 12,
    selectedMapItem: null,
    showFullScreen: false,
    icon: null,
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setMapCoordinates: (state, { payload }) => {
            const { coordinates, icon }: any = payload
            state.mapCoordinates = coordinates
            state.icon = icon
        },

        setSelectedMapItem: (state, { payload }) => {
            const { item }: any = payload
            state.selectedMapItem = item
        },

        setZoom: (state, { payload }) => {
            const { zoom }: any = payload
            state.zoom = zoom
        },
        setShowFullScreen: (state, { payload }) => {
            const { showFullScreen }: any = payload
            state.showFullScreen = showFullScreen
        },
    },
})

export const { setMapCoordinates, setZoom, setSelectedMapItem, setShowFullScreen } = globalSlice.actions

export default globalSlice.reducer
