import React from 'react'
import Icon from '@ant-design/icons';

interface CustomIconProps {
    svg: React.FC<React.SVGProps<SVGSVGElement>>;
    className?: string;
  }
const CustomIcon : React.FC<CustomIconProps>  = React.forwardRef((props, _) => <Icon component={props.svg} className={props.className}/>)

export default CustomIcon
