import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH, USER_ROUTE, FAVOURITE_PATH, BLOGGERS_PATH, BUSINESS_CATEGORY, MORE_MENU, USER_ROLE_NAME, BUSINESS_ROUTE } from '../configs/AppConfig'
import Login from '../views/auth-views/authentication/login'
import RegisterOne from '../views/auth-views/authentication/register'
import ForgotPassword from '../views/auth-views/authentication/forgot-password'
import VerifyCode from '../views/auth-views/authentication/verify-code'
import ResetPassword from '../views/auth-views/authentication/reset-password'

export interface IRoutes {
    key: string
    path: string
    component: React.LazyExoticComponent<(props: any) => JSX.Element> | any
    meta?: any
    rolePermissions?: string[]
}

export const publicRoutes: IRoutes[] = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: Login,
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: RegisterOne,
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: ForgotPassword,
    },
    {
        key: 'verify-code',
        path: `${AUTH_PREFIX_PATH}/verify-code`,
        component: VerifyCode,
    },
    {
        key: 'reset-password',
        path: `${AUTH_PREFIX_PATH}/reset-password`,
        component: ResetPassword,
    },
]

export const protectedRoutes: IRoutes[] = [
    {
        key: 'user-profile',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.PROFILE}`,
        component: React.lazy(() => import('../views/app-views/user-profile')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'more.section',
        path: `${APP_PREFIX_PATH}${MORE_MENU.MANAGE}`,
        component: React.lazy(() => import('../views/app-views/user-profile')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'account-setting',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.ACCOUNT_SETTING}`,
        component: React.lazy(() => import('../views/app-views/user-profile/account-setting')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'foodie-apply',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.APPLY_FOODIE}`,
        component: React.lazy(() => import('../views/app-views/user-profile/apply-for-foodie')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'business-claim',
        path: `${APP_PREFIX_PATH}${BUSINESS_ROUTE.CLAIM}/:businessId`,
        component: React.lazy(() => import('../views/app-views/business-claim')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'business-claim-add',
        path: `${APP_PREFIX_PATH}${BUSINESS_ROUTE.CLAIM}/create`,
        component: React.lazy(() => import('../views/app-views/business-claim-add')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'foodie-apply.success',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.APPLY_FOODIE_SUCCESS}`,
        component: React.lazy(() => import('../views/app-views/user-profile/apply-for-foodie/success')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'business.listings',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.BUSINESS_LISTINGS}`,
        component: React.lazy(() => import('../views/app-views/user-profile/business-listings')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'business.user.reviews',
        path: `${APP_PREFIX_PATH}${USER_ROUTE.USER_REVIEWS}`,
        component: React.lazy(() => import('../views/app-views/user-profile/user-reviews')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/home`,
        component: React.lazy(() => import('../views/app-views/home')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'favourites',
        path: `${APP_PREFIX_PATH}${FAVOURITE_PATH.MANAGE}`,
        component: React.lazy(() => import('../views/app-views/favourites')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'favourites.view',
        path: `${APP_PREFIX_PATH}${FAVOURITE_PATH.VIEW}/:id`,
        component: React.lazy(() => import('../views/app-views/favourites/view')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'favourites.create',
        path: `${APP_PREFIX_PATH}${FAVOURITE_PATH.CREATE}`,
        component: React.lazy(() => import('../views/app-views/favourites/widget')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'favourites.update',
        path: `${APP_PREFIX_PATH}${FAVOURITE_PATH.UPDATE}/:id`,
        component: React.lazy(() => import('../views/app-views/favourites/widget')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'bloggers',
        path: `${APP_PREFIX_PATH}${BLOGGERS_PATH.MANAGE}`,
        component: React.lazy(() => import('../views/app-views/bloggers')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'blogger.view',
        path: `${APP_PREFIX_PATH}${BLOGGERS_PATH.VIEW}/:id`,
        component: React.lazy(() => import('../views/app-views/bloggers/blogger-detail')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'blogger.content.manage',
        path: `${APP_PREFIX_PATH}${BLOGGERS_PATH.CONTENT_MANAGE}`,
        component: React.lazy(() => import('../views/app-views/bloggers/content')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'blogger.content.view',
        path: `${APP_PREFIX_PATH}${BLOGGERS_PATH.CONTENT_VIEW}/:id`,
        component: React.lazy(() => import('../views/app-views/bloggers/content/view')),
        rolePermissions: ['ALL'],
    },
    {
        key: 'blogger.content.create',
        path: `${APP_PREFIX_PATH}${BLOGGERS_PATH.CONTENT_CREATE}`,
        component: React.lazy(() => import('../views/app-views/bloggers/content/widget')),
        rolePermissions: [USER_ROLE_NAME.FOODIE],
    },
    {
        key: 'blogger.content.update',
        path: `${APP_PREFIX_PATH}${BLOGGERS_PATH.CONTENT_UPDATE}/:id`,
        component: React.lazy(() => import('../views/app-views/bloggers/content/widget')),
        rolePermissions: [USER_ROLE_NAME.FOODIE],
    },
    {
        key: 'business.category',
        path: `${APP_PREFIX_PATH}${BUSINESS_CATEGORY.MANAGE}`,
        component: React.lazy(() => import('../views/app-views/business-category/index')),
        rolePermissions: [USER_ROLE_NAME.FOODIE],
    },
]
