import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import store from '..'
import Utils from '../../utils'
import { clearAuthData } from '../slices/authSlice'
import NotificationMessage from '../../components/shared-components/NotificationMessage'

const baseQuery = fetchBaseQuery({
    baseUrl: Utils.BACK_END_API_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as any).auth.token

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }

        return headers
    },
})

const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    const unauthorizedCodeList: number[] = [403, 401]

    if (result?.error && unauthorizedCodeList.includes(Number(result?.error?.status))) {
        //logout the user
        NotificationMessage.error({ content: 'Un-authorized: Session expired!', duration: 2 })
        if (store && clearAuthData) store.dispatch(clearAuthData())
    }
    return result
}

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: baseQueryWithAuth,
    endpoints: () => ({}),
})
