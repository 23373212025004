import { Card, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import LoginForm from '../../components/LoginForm'
import Utils from '../../../../utils'

const cardStyles = { background: '#006641', boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.4)', border: '1px solid #006641' }

const Login = (props) => {
    return (
        <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
                <Col xs={24} sm={20} md={20} lg={8}>
                    <Card style={cardStyles}>
                        <div className="my-2">
                            <div className="text-center">
                                <img className="img-fluid" width={200} height={200} src={'/images/mustlisting_logo.png'} alt="" />
                            </div>

                            <Row justify="center">
                                <Col xs={24} sm={24} md={20} lg={20}>
                                    <LoginForm otherSignIn {...props} />
                                    <div style={{ justifyContent: 'center', display: 'grid', textAlign: 'center' }}>
                                        <span style={{ color: 'white' }}>
                                            Don't have an account?{' '}
                                            <Link style={{ color: Utils.YELLOW_COLOR }} to="/auth/register">
                                                Sign Up
                                            </Link>
                                        </span>
                                        <Link className="mt-2" style={{ color: Utils.YELLOW_COLOR }} to="/auth/forgot-password">
                                            Forgot password?
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Login
